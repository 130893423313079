import { getInitPeriodFilter } from "@helpers/_functions";
import { makeActionHandler } from "./_reducerUtils";

export const disciplinaryReducer = {
  initialState: {
    disciplinaryCategory: {
      list: [],
      isLoaded: false,
    },
    disciplinaryGradation: {
      list: [],
      isLoaded: false,
    },
    disciplinary: {
      list: [],
      filter:{
        period: getInitPeriodFilter(),
      },
      isLoaded: false,
      isQueryProcessing: false
    }
  },
  handlers: {
    "disciplinary/SET_DISCIPLINARY_CATEGORY": makeActionHandler("disciplinaryCategory"),
    "disciplinary/SET_DISCIPLINARY_GRADATION": makeActionHandler("disciplinaryGradation"),
    "disciplinary/SET_DISCIPLINARY":  makeActionHandler("disciplinary"),

    "RESET_ALL": () => disciplinaryReducer.initialState, // Réinitialiser l'état du reducer
  }
};

export default disciplinaryReducer;
