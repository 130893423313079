import { makeActionHandler } from "./_reducerUtils";

export const allNewsData = {
  initialState: {
    newsData: {
      list: [],
      isLoaded: false,
      filter: {},
      isProcessing: false,
      isCreatingNew: false,
    },
    newsTag: {
      list: [],   
      isLoaded: false,
    },
    enterpriseNewsData: {
      list: [],
      isLoaded: false,
      filter: {},
      isProcessing: false,
      isCreatingNew: false,
    }
  },
  handlers: {
    SET_NEWS_DATA: makeActionHandler("newsData"),
    SET_ENTERPRISE_NEWS_DATA: makeActionHandler("enterpriseNewsData"),
    SET_NEWS_TAG: makeActionHandler("newsTag"), 
    RESET_ALL: () => allNewsData.initialState,
  },
};

export default allNewsData;
