import { makeActionHandler } from "./_reducerUtils";

const statusReducer = {
  initialState: {
    status: {
      list: [],
      isLoaded: false,
      isQueryProcessing: false
    },
  },
  handlers: {
    "status/SET_LIST": makeActionHandler("status"),
    "RESET_ALL": () => statusReducer.initialState
  }
};

export default statusReducer;
