import {
  AVAILABLE_POSTES,
  CANDIDATES,
  CARREER_PAGE,
  CV_BANK,
  MODELE,
  RECRUITS,
  STATISTICS
} from "@helpers/_constants";
import {
  getTabValue,
  getPermissionTree,
  getPermissionValue,
  combineObjectAndClone,
  getAllPermissionValue,
  getSections
} from "@helpers/_functions";

const dataEmployer = {
  dashboard: {
    value: "DDashboard",
    defaultActive: "active",
    label: "Tableau De Bord"
  },
  mySchedule: {
    label: "Mon horaire",
    value: "myhours",
    children: [
      {
        value: "DWorkingHours",
        defaultActive: "active",
        label: "Horaires de travail"
      },
      {
        value: "DWolidayAndAbsences",
        defaultActive: "active",
        label: "Vacances et absences"
      }
    ]
  },
  trackingHours: {
    value: "DHoursSuivi",
    defaultActive: "active",
    label: "Suivi des heures"
  },
  news: {
    label: "Nouvelles",
    value: "DNews"
  },
  myProfile: {
    label: "Ma fiche employé",
    value: "DMyfiche",
    children: [
      {
        value: "DInfopers",
        defaultActive: "active",
        label: "Informations personnelles",
        children: [
          {
            value: "DInfopersSectionEmailsAndPhones",
            defaultActive: "active",
            label: "Courriels et téléphones"
          },
          {
            value: "DInfopersSectionAdress",
            defaultActive: "active",
            label: "Adresse"
          },
          {
            value: "DInfopersSectionHealthAndFoods",
            defaultActive: "active",
            label: "Santé - Allergies et préférences alimentaires"
          },
          {
            value: "DInfopersSectionClothingSizes",
            defaultActive: "active",
            label: "Taille des vêtements"
          },
          {
            value: "DInfopersSectionFirstEmergencyContact",
            defaultActive: "active",
            label: "Premier Contact d'urgence"
          },
          {
            value: "DInfopersSectionSecondEmergencyContact",
            defaultActive: "active",
            label: "Deuxième Contact d'urgence"
          }
        ]
      },
      {
        value: "DEmploisalary",
        defaultActive: "active",
        label: "Emploi, salaire et vacances",
        children: [
          {
            value: "DEmploisalarySectionJob",
            defaultActive: "active",
            label: "Emploi"
          },
          {
            value: "DEmploisalarySectionBankingAndInsuranceInformation",
            defaultActive: "active",
            label: "Informations bancaires et d'assurance"
          },
          {
            value: "DEmploisalarySectionUnavailabilityPeriodsAndDeparture",
            defaultActive: "active",
            label: "Périodes d'indisponibilités et départ"
          },
          {
            value:"DEmploisalarySectionDisciplinaryTracking",
            defaultActive: "active",
            label: "Suivi disciplinaire"
          },
          {
            value: "DEmploisalarySectionSalaryAndAdvantages",
            defaultActive: "active",
            label: "Parcours professionnel, salaire et avantages"
          },
          {
            value: "DEmploisalarySectionHolidays",
            defaultActive: "active",
            label: "Vacances"
          }
        ]
      },
      {
        // value: "DCoursCertify",
        value: "DDocument",
        defaultActive: "active",
        label: "Documents",
        children: [
          {
            value: "DDocumentSectionDocuments",
            defaultActive: "active",
            label: "Documents"
          },
          {
            value: "DDocumentSectionDocumentsRH",
            defaultActive: "active",
            label: "Documents RH"
          }
        ]
      },
      {
        value: "DCertFormation",
        defaultActive: "active",
        label: "Permis et formations",
        children: [
          {
            value: "DCertFormationSectionLicensesAndCertifications",
            defaultActive: "active",
            label: "Permis et Certifications"
          },
          {
            value: "DCertFormationSectionFormations",
            defaultActive: "active",
            label: "Formations"
          }
        ]
      },
      {
        value: "DMaterial",
        defaultActive: "active",
        label: "Matériel assigné"
      },
      {
        label: "Paramètres du compte",
        value: "DParameters",
        defaultActive: "active"
      }
    ]
  },
  documentation: {
    label: "Documentation",
    value: "documentation",
    children: [
      {
        value: "DDocumentation",
        defaultActive: "active",
        label: "Documentation"
      },
      {
        value: "DOrganigram",
        defaultActive: "active",
        label: "Organigramme"
      }
    ]
  },
  expensesNotes: {
    label: "Remboursements",
    value: "DExpensesNotes"
  },
  assignedTask: {
    label: "Tâches",
    value: "assignedTask",
    children: [
      {
        value: "DAssignedTask",
        defaultActive: "active",
        label: "Liste"
      }
    ]
  },
  assessmentDevelopmentPlan: {
    label: "Évaluations et plan de développement",
    value: "DAssessmentDevelopmentPlan",
    children: [
      {
        value: "DAssessmentDevelopmentPlanSectionSkills",
        defaultActive: "active",
        label: "Compétences"
      },
      {
        value: "DAssessmentDevelopmentPlanSectionPerformances",
        defaultActive: "active",
        label: "Performances"
      },
      {
        value:
          "DAssessmentDevelopmentPlanSectionIndividualObjectivesAndFollowUp",
        defaultActive: "active",
        label: "Objectifs et suivis individuels"
      }
    ]
  }
  // documentation: {
  //   label: "Documentation",
  //   value: "DDocumentation"
  // }
};

const dataEntreprise = {
  employees: {
    label: "Employés",
    value: "listEmployer",
    children: [
      {
        label: "Liste",
        value: "listBuild",
        defaultActive: "active",
        childs: {
          employeeFiles: {
            label: "Dossiers Employés",
            value: "employeeFiles",
            children: [
              {
                value: "DListInfopers",
                defaultActive: "active",
                label: "Informations personnelles",
                children: [
                  {
                    value: "DListInfopersSectionEmailsAndPhones",
                    defaultActive: "active",
                    label: "Courriels et téléphones"
                  },
                  {
                    value: "DListInfopersSectionAdress",
                    defaultActive: "active",
                    label: "Adresse"
                  },
                  {
                    value: "DListInfopersSectionHealthAndFoods",
                    defaultActive: "active",
                    label: "Santé - Allergies et préférences alimentaires"
                  },
                  {
                    value: "DListInfopersSectionClothingSizes",
                    defaultActive: "active",
                    label: "Taille des vêtements"
                  },
                  {
                    value: "DListInfopersSectionFirstEmergencyContact",
                    defaultActive: "active",
                    label: "Premier Contact d'urgence"
                  },
                  {
                    value: "DListInfopersSectionSecondEmergencyContact",
                    defaultActive: "active",
                    label: "Deuxième Contact d'urgence"
                  }
                ]
              },
              {
                label: "Emploi, salaire et vacances",
                value: "DListEmploisalary",
                defaultActive: "active",
                children: [
                  {
                    value: "DListEmploisalarySectionJob",
                    defaultActive: "active",
                    label: "Emploi"
                  },
                  {
                    value:
                      "DListEmploisalarySectionBankingAndInsuranceInformation",
                    defaultActive: "active",
                    label: "Informations bancaires et d'assurance"
                  },
                  {
                    value:
                      "DListEmploisalarySectionUnavailabilityPeriodsHealthAndSecurity",
                    defaultActive: "active",
                    label: "Périodes d'indisponibilités, santé et sécurité"
                  },
                  {
                    value:"DListEmploisalarySectionDisciplinaryTracking",
                    defaultActive: "active",
                    label: "Suivi disciplinaire"
                  },
                  {
                    value: "DListEmploisalarySectionDeparture",
                    defaultActive: "active",
                    label: "Départ"
                  },
                  {
                    value: "DListEmploisalarySectionSalaryAndAdvantages",
                    defaultActive: "active",
                    label: "Parcours professionnel, salaire et avantages"
                  },
                  {
                    value: "DListEmploisalarySectionHolidays",
                    defaultActive: "active",
                    label: "Vacances"
                  }
                ]
              },
              {
                label: "Documents et notes",
                // value: "DListCoursCertify",
                value: "DListDocumentNote",
                defaultActive: "active",
                children: [
                  {
                    value: "DListDocumentNoteSectionDocuments",
                    defaultActive: "active",
                    label: "Documents"
                  },
                  {
                    value: "DListDocumentNoteSectionDocumentsRH",
                    defaultActive: "active",
                    label: "Documents RH"
                  },
                  {
                    value: "DListDocumentNoteSectionNotes",
                    defaultActive: "active",
                    label: "Notes"
                  },
                  {
                    value: "DListDocumentNoteSectionNotesRH",
                    defaultActive: "active",
                    label: "Notes RH"
                  }
                ]
              },
              {
                label: "Permis et formations",
                value: "DListCertFormation",
                defaultActive: "active",
                children: [
                  {
                    value: "DListCertFormationSectionLicensesAndCertifications",
                    defaultActive: "active",
                    label: "Permis et Certifications"
                  },
                  {
                    value: "DListCertFormationSectionFormations",
                    defaultActive: "active",
                    label: "Formations"
                  }
                ]
              },
              {
                label: "Matériel assigné",
                value: "DListMaterial",
                defaultActive: "active"
              },
              {
                label: "Paramètres du compte",
                value: "DListParameters",
                defaultActive: "active"
              }
            ]
          }
        }
      },
      {
        value: "statistiquesBuild",
        defaultActive: "active",
        label: "Statistiques",
        children: [
          {
            value: "statistiquesBuildSectionSalaryAndLengthOfService",
            defaultActive: "active",
            label: "Salaires et ancienneté"
          },
          {
            value: "statistiquesBuildSectionHolidays",
            defaultActive: "active",
            label: "Vacances, absences et indisponibilités"
          },
          {
            value: "statistiquesBuildSectionPersonelMovements",
            defaultActive: "active",
            label: "Mouvements de personnel"
          },
          {
            value: "statistiquesBuildSectionUnavalaibilityHealthAndSecurity",
            defaultActive: "active",
            label: "Indisponibilités, santé et sécurité"
          },
          {
            value: "statistiquesBuildSectionPersonelComposition",
            defaultActive: "active",
            label: "Composition du personnel"
          },
          {
            value: "statistiquesBuildSectionPersonelDistribution",
            defaultActive: "active",
            label: "Répartition du personnel"
          },
          {
            value: "statistiquesBuildSectionHealthAndFoodPreferences",
            defaultActive: "active",
            label: "Santé - Allergies et préférences alimentaires"
          }
        ]
      },
      {
        value: "anniversaryBuild",
        defaultActive: "active",
        label: "Anniversaires"
      },
      {
        value: "beginEnd",
        defaultActive: "active",
        label: "Arrivées et départs",
        children: [
          {
            value: "beginEndSectionEmployeeFlow",
            defaultActive: "active",
            label: "Flux d’employés"
          },
          {
            value: "beginEndSectionIntegrationAndDepartureProtocol",
            defaultActive: "active",
            label: "Protocoles d’intégration et de départ"
          }
        ]
      },
      {
        value: "indisponibility",
        defaultActive: "active",
        label: "Indisponibilités, santé et sécurité"
      },
      {
        value: "disciplinary",
        defaultActive: "active",
        label: "Suivis disciplinaires"
      }
    ]
  },
  schedulePlanning: {
    label: "Planification des horaires",
    value: "hoursPlan",
    children: [
      {
        value: "hoursBuild",
        defaultActive: "active",
        label: "Horaires de travail"
      },
      {
        value: "employeeStatus",
        defaultActive: "active",
        label: "Statut des employés"
      },
      {
        value: "vacancyAbsence",
        defaultActive: "active",
        label: "Vacances et absences"
      }
    ]
  },
  workedHours: {
    label: "Heures travaillées",
    value: "hoursWorked",
    children: [
      {
        value: "compteRendu",
        defaultActive: "active",
        label: "Compte rendu"
      },
      {
        value: "recapitulatifMensuel",
        defaultActive: "active",
        label: "Récapitulatif mensuel"
      },
      {
        value: "repartition",
        defaultActive: "active",
        label: "Répartition par projets",
        children: [
          {
            value: "repartitionSectionAssignmentHourPerProject",
            defaultActive: "active",
            label: "Assignation des heures par projet"
          },
          {
            value: "repartitionSectionTrainingHours",
            defaultActive: "active",
            label: "Heures de Formation"
          },
          {
            value: "repartitionSectionAssignmentManagement",
            defaultActive: "active",
            label: "Gestion des assignations"
          }
        ]
      },
      {
        value: "saisieDesHeuresEtPointage",
        defaultActive: "active",
        label: "Saisie des heures et pointages",
        children: [
          {
            value: "saisieDesHeuresEtPointageSectionPonting",
            defaultActive: "active",
            label: "Gestion des pointeuses externes"
          }
        ]
      }
    ]
  },
  tasks: {
    label: "Tâches",
    defaultActive: "active",
    value: "tasks"
  },
  expensesNotes: {
    label: "Remboursements",
    defaultActive: "active",
    value: "expensesNotes"
  },
  news: {
    label: "Nouvelles",
    defaultActive: "active",
    value: "news"
  },
  documentation: {
    label: "Documentation",
    value: "documentation",
    children: [
      {
        value: "documents",
        defaultActive: "active",
        label: "Documents"
      }
    ]
  },
  formations: {
    label: "Formations et certifications",
    value: "formationEnterprise",
    children: [
      {
        value: "formations",
        defaultActive: "active",
        label: "Formations"
      },
      {
        value: "certifications",
        defaultActive: "active",
        label: "Certifications"
      }
    ]
  },
  recruitments: {
    label: "Recrutements",
    value: "recrutements",
    children: [
      {
        value: AVAILABLE_POSTES,
        defaultActive: "active",
        label: "Postes à pourvoir"
        // children:[
        //   {
        //     value: "availablePostesSectionArchiveFolder",
        //     defaultActive: "active",
        //     label: "Dossier Archive"
        //   }
        // ]
      },
      {
        value: CANDIDATES,
        defaultActive: "active",
        label: "Candidats"
      },
      {
        value: RECRUITS,
        defaultActive: "active",
        label: "Recrues"
      },
      {
        value: CV_BANK,
        defaultActive: "active",
        label: "Banque de CVs"
      },
      {
        value: MODELE,
        defaultActive: "active",
        label: "Modèles",
        children: [
          {
            value: "modeleSectionPipelines",
            defaultActive: "active",
            label: "Modèles de pipeline"
          },
          {
            value: "modeleSectionForms",
            defaultActive: "active",
            label: "Modèles de formulaires"
          },
          {
            value: "modeleSectionEmails",
            defaultActive: "active",
            label: "Modèles de courriels"
          }
        ]
      },
      {
        value: CARREER_PAGE,
        defaultActive: "active",
        label: "Site Carrière"
      },
      {
        value: STATISTICS,
        defaultActive: "active",
        label: "Statistiques"
      }
    ]
  },
  materials: {
    label: "Matériels",
    value: "material",
    children: [
      {
        value: "materialDashboard",
        defaultActive: "active",
        label: "Dashboard",
        children: [
          {
            value: "materialDashboardSectionExpiringEquipment",
            defaultActive: "active",
            label: "Liste du matériel arrivant à terme"
          },
          {
            value: "materialDashboardSectionLostStolenEquipment",
            defaultActive: "active",
            label: "Liste du matériel Perdu/volé"
          },
          {
            value: "materialDashboardSectionDamagedNoFunctionalEquipment",
            defaultActive: "active",
            label: "Liste du matériel Endommagé/Non-fonctionnel"
          }
        ]
      },
      {
        value: "materialInventory",
        defaultActive: "active",
        label: "Inventaire"
      }
    ]
  },
  assesments: {
    label: "Évaluations",
    value: "assesments",
    children: [
      {
        value: "skillsState",
        defaultActive: "active",
        label: "État des compétences"
      },
      {
        value: "performance",
        defaultActive: "active",
        label: "Performances"
      },
      {
        value: "assignmentsFrequency",
        defaultActive: "active",
        label: "Attributions et fréquences des évaluations"
      },
      {
        value: "assessmentsHistory",
        defaultActive: "active",
        label: "Historique"
      }
    ]
  },
  developmentPlan: {
    label: "Plans de développement",
    value: "DevelopmentPlan"
  },
  settings: {
    label: "Paramètres",
    value: "parameters",
    children: [
      {
        value: "entreprise",
        defaultActive: "active",
        label: "Entreprise",
        children: [
          {
            value: "entrepriseSectionGeneralInformationAndLogos",
            defaultActive: "active",
            label: "Informations générales et logos"
          },
          {
            value: "entrepriseSectionLocations",
            defaultActive: "active",
            label: "Emplacements"
          },
          {
            value: "entrepriseSectionShortcutsToDashboard",
            defaultActive: "active",
            label: "Raccourcis disponibles dans le tableau de bord"
          },
          {
            value: "entrepriseSectionPersonalizedEmails",
            defaultActive: "active",
            label: "Courriels de correspondance personnalisés"
          },
          {
            value: "entrepriseSectionPersonalizedLoginPage",
            defaultActive: "active",
            label: "Page de connexion personnalisée"
          },
          {
            value: "entrepriseSectionSynchronisationWithNethris",
            defaultActive: "active",
            label: "Synchronisation avec des systèmes externes"
          }
        ]
      },
      {
        value: "postsGroupsRolesSettings",
        defaultActive: "active",
        label: "Postes, groupes et gestion des accès",
        children: [
          {
            value: "postsGroupsRolesSettingsSectionPostsAndGroups",
            defaultActive: "active",
            label: "Postes et groupes",
            children: [
              {
                value: "postsGroupsRolesSettingsSectionPosts",
                defaultActive: "active",
                label: "Postes"
              },
              {
                value: "postsGroupsRolesSettingsSectionGroups",
                defaultActive: "active",
                label: "Groupes"
              }
            ]
          },
          {
            value: "postsGroupsRolesSettingsSectionAccessManagement",
            defaultActive: "active",
            label: "Gestion des accès",
            children: [
              {
                value: "postsGroupsRolesSettingsSectionPermissions",
                defaultActive: "active",
                label:
                  "Permissions (modules et pages accessibles aux utilisateurs)"
              },
              {
                value: "postsGroupsRolesSettingsSectionAccessAndVisibleData",
                defaultActive: "active",
                label: "Droit d'accès et données visibles"
              }
            ]
          }
        ]
      },
      {
        value: "calendar",
        defaultActive: "active",
        label: "Calendrier"
      },
      {
        value: "workingHoursSettings",
        defaultActive: "active",
        label: "Heures travaillées",
        children: [
          {
            value: "workingHoursSettingsSectionWorkedHoursTypes",
            defaultActive: "active",
            label: "Types d'heures travaillées"
          },
          {
            value: "workingHoursSettingsSectionVacationAccounts",
            defaultActive: "active",
            label: "Comptes de vacances et absences"
          },
          {
            value: "workingHoursSettingsSectionTimeBank",
            defaultActive: "active",
            label: "Banque de temps"
          },
          {
            value: "workingHoursSettingsSectionPrimes",
            defaultActive: "active",
            label: "Primes"
          },
          {
            value: "workingHoursSettingsSectionStatus",
            defaultActive: "active",
            label: "Statuts"
          }
        ]
      },
      {
        value: "expensesNotesSettings",
        defaultActive: "active",
        label: "Remboursements",
        children: [
          {
            value: "expensesNotesSettingsSectionCategories",
            defaultActive: "active",
            label: "Catégories de remboursements"
          },
          {
            value: "expensesNotesSettingsSectionTotalPerYear",
            defaultActive: "active",
            label: "Km total sur l’année"
          },
          {
            value: "expensesNotesSettingsSectionApplicableRates",
            defaultActive: "active",
            label: "Taxes applicables"
          }
        ]
      },
      {
        value: "recruitmentAts",
        defaultActive: "active",
        label: "Recrutement",
        children: [
          {
            value: "recruitmentAtsSectionRefusalReasons",
            defaultActive: "active",
            label: "Raisons de refus"
          },
          {
            value: "recruitmentAtsSectionApplicationSources",
            defaultActive: "active",
            label: "Sources des candidatures"
          },
          {
            value: "recruitmentAtsSectionChiefRecruiters",
            defaultActive: "active",
            label: "Recruteurs en chef"
          },
          {
            value: "recruitmentAtsSectionLoginToJobboards",
            defaultActive: "active",
            label: "Connexions aux jobboardss"
          }
        ]
      },
      {
        value: "assismentSettings",
        defaultActive: "active",
        label: "Évaluations",
        children: [
          {
            value: "assismentSettingsSectionSkillsManagement",
            defaultActive: "active",
            label: "Gestion des compétences"
          },
          {
            value: "assismentSettingsSectionPerformanceManagement",
            defaultActive: "active",
            label: "Gestion des performances"
          },
          {
            value: "assismentSettingsSectionQuestions",
            defaultActive: "active",
            label: "Questions"
          }
        ]
      }
    ]
  }
};

export const permissionTreeTranslator = (data = [], translate = (a) => a) =>
  data.map((item) => {
    item.label = translate(item.key);
    if (item.children?.length) {
      item.children = permissionTreeTranslator(item.children, translate);
    }
    return item;
  });

export const permissionEmployeeLists = getAllPermissionValue(dataEmployer);
export const permissionEnterpriseLists = getAllPermissionValue(dataEntreprise);
export const employeeMenuPermission = getPermissionValue(dataEmployer);
export const enterpriseMenuPermission = getPermissionValue(dataEntreprise);
export const permissionEmployeeTabs = getTabValue(dataEmployer);
export const permissionEnterpriseTabs = getTabValue(dataEntreprise);
export const permissionEmployeeTree = getPermissionTree(dataEmployer);
export const permissionSectionsEmployeeLists = getSections(dataEmployer);
export const permissionSectionsEnterprisesLists = getSections(dataEntreprise);
export const getSpecificsEmployerSections = (keyWords) =>
  permissionSectionsEmployeeLists?.filter((item) =>
    keyWords.some((keyword) => item.includes(keyword))
  );
export const getSpecificsEnterpriseSections = (keyWords) =>
  permissionSectionsEnterprisesLists?.filter((item) =>
    keyWords.some((keyword) => item.includes(keyword))
  );
export const permissionEnterpriseTree = getPermissionTree(dataEntreprise);
export const permissions = combineObjectAndClone(
  {
    ...employeeMenuPermission,
    employee: permissionEmployeeLists
  },
  {
    ...enterpriseMenuPermission,
    enterprise: permissionEnterpriseLists
  }
);

/*
permissions = {
      "mySchedule": [
          "DWorkingHours",
          "DWolidayAndAbsences"
      ],
      "trackingHours": [
          "DHoursSuivi"
      ],
      "news": [
          "news"
      ],
      "myProfile": [
          "DInfopers",
          "DEmploisalary",
          "DCoursCertify",
          "DMaterial",
          "DParameters"
      ],
      "assignedTask": [
          "DAssignedTask",
      ],
      "employee": [
          "DWorkingHours",
          "DWolidayAndAbsences",
          "DHoursSuivi",
          "DNews",
          "DInfopers",
          "DEmploisalary",
          "DCoursCertify",
          "DMaterial",
          "DParameters",
          "DAssignedTask",
      ],
      "employeeFiles": [
          "DListInfopers",
          "DListEmploisalary",
          "DListCoursCertify",
          "DListMaterial",
          "DListParameters"
      ],
      "employees": [
          "listBuild",
          "statistiquesBuild",
          "anniversaryBuild",
          "beginEnd"
      ],
      "schedulePlanning": [
          "hoursBuild",
          "vacancyAbsence"
      ],
      "workedHours": [
          "compteRendu",
          "recapitulatifMensuel",
          "repartition",
          "saisieDesHeuresEtPointage"
      ],
      "recruitments": [
          "availablePostes",
          "applications",
          "cvBank",
          "modele",
          "careerPage"
      ],
      "materials": [
          "materialDashboard",
          "materialInventory"
      ],
      "settings": [
          "entreprise",
          "postsGroupsRolesSettings",
          "calendar",
          "assismentSettings",
          "expensesNotesSettings",
          "recruitmentAts"
      ],
      "assesments": [
          "skillsState",
          "performance",
          "assignmentsFrequency",
      ],
      "enterprise": [
          "DListInfopers",
          "DListEmploisalary",
          "DListCoursCertify",
          "DListMaterial",
          "DListParameters",
          "listBuild",
          "statistiquesBuild",
          "anniversaryBuild",
          "beginEnd",
          "hoursBuild",
          "vacancyAbsence",
          "compteRendu",
          "recapitulatifMensuel",
          "repartition",
          "saisieDesHeuresEtPointage",
          "news",
          "availablePostes",
          "applications",
          "cvBank",
          "modele",
          "careerPage",
          "materialDashboard",
          "materialInventory",
          "entreprise",
          "postsGroupsRolesSettings",
          "calendar",
          "skillsState",
          "performance",
          "assignmentsFrequency",
          "assismentSettings"
      ]
  }
*/
