import React from "react";

const FloatButton = ({
  id = null,
  size = "",
  spaceButton = "",
  color = "",
  onClick = () => {},
  children
}) => {
  return (
    <button
      id={id}
      className={
        size +
        " " +
        spaceButton +
        " " +
        " arh-flex arh-shrink-0 arh-cursor-pointer arh-items-center arh-justify-center arh-rounded-full arh-border arh-border-solid arh-border-white arh-transition-all arh-duration-200 arh-ease-in " +
        color
      }
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default FloatButton;
