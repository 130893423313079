import { customizeError, getResponseData } from "@helpers/_functions";
import BaseService from "./base.service";
import http from "../http-common";

class DisciplinaryService extends BaseService {
  constructor() {
    super("disciplinary");
  }

  getByEmployer(employeId, params = {}) {
    return http.get(`${this.path}/getByEmployer/${employeId}`, { params })
      .then(res => getResponseData(res))
      .catch((err) => Promise.reject(customizeError(err)));
  }

  upsertDisciplinary(body, params = {}) {
    return http.post(`${this.path}/upsert`, body, { params })
      .then(res => getResponseData(res))
      .catch((err) => Promise.reject(customizeError(err)));
  }

  deleteDisciplinary(id, params = {}) {
    return http.delete(`${this.path}/deleteById/${id}`, { params })
      .then(res => getResponseData(res))
      .catch((err) => Promise.reject(customizeError(err)));
  }

  getDisciplinariesByEnterprise(id, params = {}) {
    params = {
      ...params,
      userId: localStorage.getItem("userId")
    };
    return http.get(`${this.path}/lists/${id}`, { params })
      .then(res => getResponseData(res))
      .catch((err) => Promise.reject(customizeError(err)));
  }
}

export default new DisciplinaryService();
