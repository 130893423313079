import { combineObjectWithoutCloning } from "@helpers/_functions";

import { makeActionHandler } from "./_reducerUtils";

export const settingsReducer = {
  initialState: {
    dayOffEnterprises: {
      list: [],
      isLoaded: false,
      year: new Date().getFullYear()
    },
    enterprise: {},
    enterpriseLogos: null,
    unavailabilityOptions: {
      typeList: [],
      placeList: [],
      mainCauseList: [],
      gravityList: [],
      injuryNatureList: [],
      bodyLocationList: []
    },
    insuranceAndBanqOptions: {
      insurancePlanNumberList: [],
      coverageTypeList: []
    },
    groups: {
      list: []
    },
    employersTree: [],
    refusalReasons: [],
    applicationSources: [],
    allWorkedHoursTypes: {
      list: [], // all types
      isLoaded: false
    },
    holidayName: {},
    // enterpriseData:{
    //   listNotSynchronised:[],
    //   listSynchronised:[],
    //   listSynchronisedHadDifferentsAttriubutes:[],
    //   isLoading:false
    // },
    isLoadedAllWorkedHoursTypes: false,
    workedHoursTypes: {
      list: [], // types active
      isLoaded: false
    },
    employeurDAccounts: [],
    showEmployeurDInfo: {},
    externalLinks: {
      isLoaded: false,
      list: []
    },
    accessProfiles: {
      isLoaded: false,
      list: []
    },
    coefficientMultiplier: {
      list: [],
      isLoaded: false
    },
    primes: {
      isLoaded: false,
      list: []
    },
    status: {
      list: [],
      isLoaded: false,
    },
  },
  handlers: {
    "settings/SET_DAY_OFF_ENTERPRISES": makeActionHandler("dayOffEnterprises"),
    "settings/SET_ENTERPRISE": (state, payload) =>
      combineObjectWithoutCloning(state, { enterprise: payload }),
    "settings/SET_ENTERPRISE_LOGOS": (state, payload) =>
      combineObjectWithoutCloning(state, { enterpriseLogos: payload }),
    "settings/SET_UNAVAILABILITY_OPTIONS": (state, payload) =>
      combineObjectWithoutCloning(state, { unavailabilityOptions: payload }),
    "settings/SET_INSURANCEANDCOVERAGE_OPTIONS": (state, payload) =>
      combineObjectWithoutCloning(state, { insuranceAndBanqOptions: payload }),
    "settings/SET_EMPLOYERS_TREE": (state, payload) =>
      combineObjectWithoutCloning(state, { employersTree: payload }),
    "settings/SET_REFUSAL_REASONS": (state, payload) =>
      combineObjectWithoutCloning(state, { refusalReasons: payload }),
    "settings/SET_APPLICATION_SOURCES": (state, payload) =>
      combineObjectWithoutCloning(state, { applicationSources: payload }),
    "settings/SET_WORKEDHOURS_TYPES": makeActionHandler("workedHoursTypes"),
    "settings/SET_ALLWORKEDHOURS_TYPES": (state, payload) =>
      combineObjectWithoutCloning(state, { allWorkedHoursTypes: payload }),
    "settings/SET_HOLIDAY_NAME": (state, payload) =>
      combineObjectWithoutCloning(state, { holidayName: payload }),
    "settings/SET_IS_LOADED_ALLWORKEDHOURS_TYPES": (state, payload) =>
      combineObjectWithoutCloning(state, {
        isLoadedAllWorkedHoursTypes: payload
      }),
    "settings/SET_GROUPS": (state, payload) =>
      combineObjectWithoutCloning(state, { groups: payload }),
    "settings/SET_EMPLOYEURD_ACCOUNTS": (state, payload) =>
      combineObjectWithoutCloning(state, { employeurDAccounts: payload }),
    "settings/SET_SHOW_EMPLOYEURD_INFO": (state, payload) =>
      combineObjectWithoutCloning(state, { showEmployeurDInfo: payload }),
    "settings/SET_EXTERNAL_LINKS": (state, payload) =>
      combineObjectWithoutCloning(state, { externalLinks: payload }),
    "settings/SET_ACCESS_PROFILE": makeActionHandler("accessProfiles"),
    "settings/SET_PRIMES": makeActionHandler("primes"),
    "settings/SET_COEFFICIENT_MULTIPLIER": makeActionHandler(
      "coefficientMultiplier"
    ),
    "settings/SET_STATUS": makeActionHandler("status"),
    "RESET_ALL": () => settingsReducer.initialState //RESET_ALL for all redux reducers
  }
};

export default settingsReducer;
