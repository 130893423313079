import {  getResponseData } from "@helpers/_functions";

import http from "../http-common";
import BaseService from "./base.service";

class PinServices extends BaseService {
  constructor() {
    super("pin");
  }

  upsert(data, params = {}) {
    return http
      .post(`${this.path}/`, data, { params })
      .then((response) => getResponseData(response));
  }
}

export default new PinServices();
