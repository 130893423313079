import {
  customizeError,
  checkEnterpriseId,
  getResponseData
} from "@helpers/_functions";
import http from "../../http-common";
import BaseOnboardingService from "./baseOnboarding.service";

class UnavailabilityService extends BaseOnboardingService {
  constructor() {
    super("unaivalability");
  }
  rehire(data) {
    return http.post(`${this.path}/rehire/`, data);
  }
  isLastAdmin(id) {
    return http.get(`${this.path}/isLastAdmin/${id}`);
  }
  checkPermission(id) {
    return http.get(`${this.path}/checkPermission/${id}`);
  }

  getAllFlowByEnterprise(queries = {}) {
    const params = {
      filter: JSON.stringify(queries),
      userId: localStorage.getItem("userId")
    };

    return http
      .get(`${this.path}/enterprise/${checkEnterpriseId()}`, { params })
      .then((response) => getResponseData(response))
      .catch((err) => Promise.reject(customizeError(err)));
  }

  getOptionsByEnterprise() {
    return http
      .get(`${this.path}/options/${checkEnterpriseId()}`)
      .then((response) => getResponseData(response))
      .catch((err) => Promise.reject(customizeError(err)));
  }

  getAllIndisponibilityByEnterprise(queries = {}) {
    const params = {
      filter: JSON.stringify(queries),
      userId: localStorage.getItem("userId")
    };

    return http
      .get(`${this.path}/indisponibility-enterprise/${checkEnterpriseId()}`, { params })
      .then((response) => getResponseData(response))
      .catch((err) => Promise.reject(customizeError(err)));
  }
}

export default new UnavailabilityService();
