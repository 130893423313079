import moment from "moment-timezone";

import { CONGE_STATUS_APPROUVED } from "@helpers/_constants";
import { Employer } from "@services/all.services";

export const toFrenchDate = (date) => {
  return moment(date).locale("fr").format("DD MMMM");
};

export const toFrenchFullDate = (date) => {
  return moment(date).locale("fr").format("DD MMMM YYYY");
};

export const toFrenchLongDate = (date) => {
  return moment(date).locale("fr").format("dddd DD MMMM");
};

export const toFrenchLongFullDate = (date) => {
  return moment(date).locale("fr").format("dddd DD MMMM YYYY");
};

export const getFrenchYear = (date) => {
  return moment(date).locale("fr").format("YYYY");
};

export const compareDate = (date1, date2) => {
  return (
    moment(date1).format("YYYY MM DD") === moment(date2).format("YYYY MM DD")
  );
};

export const scheduleDateMsg = (data) => {
  if (!Array.isArray(data)) {
    return "";
  }
  // Get start data of first hour and end date
  const startDate = data[0].start;
  const endDate = data[data.length - 1].end;
  const isEqualStartAndEnd = compareDate(startDate, endDate);
  return isEqualStartAndEnd
    ? `${toFrenchLongDate(startDate)} ${getFrenchYear(startDate)}`
    : `${toFrenchLongDate(startDate)} au ${toFrenchLongDate(
      endDate
    )} ${getFrenchYear(endDate)}`;
};

export const congeDateMsg = (data) => {
  const isEqualStartAndEnd = compareDate(data.start, data.end);
  return isEqualStartAndEnd
    ? `pour le ${toFrenchDate(data.start)} ${getFrenchYear(data.start)}`
    : `du ${toFrenchDate(data.start)} au ${toFrenchDate(
      data.end
    )} ${getFrenchYear(data.end)}`;
};

export const statusConge = (data) => {
  const statusConge =
    data.status === CONGE_STATUS_APPROUVED ? "acceptée" : "refusée";
  return statusConge;
};

export const getParentFolderName = (key) => {
  if (!key.length) return new Error("no given key");
  const arrKey = key.split("/");
  return arrKey[arrKey?.length - 2];
};

// export const getEmployerPersonnalInfo = (data) => {
//     let employer = {}
//     if(data?.Employer?.[0]?._id) {
//         employer = data?.Employer?.[0];
//     } else if(data?.Employer?._id) {
//         employer = data?.Employer;
//     } else {
//         console.error("ERROR, no employer info on data");
//         return;
//     }
//     const { legalFirstName, legalName } = employer?.personnalInformation?.[0];
//     return { legalFirstName, legalName };
// }

export const getEmployerPersonnalInfo = (data) =>
  new Promise(async (resolve, reject) => {
    let employer = {};
    if (data?.Employer?.[0]?._id) {
      employer = data?.Employer?.[0];
    } else if (data?.Employer?._id) {
      employer = data?.Employer;
    } else if(data?.Employer) {
      employer = data?.Employer;
    } else {
      console.error("ERROR, no employer info on data");
    }
    try {
      if (employer?.personnalInformation?.[0]) {
        const { legalFirstName, legalName } = employer?.personnalInformation?.[0];
        resolve({ legalFirstName, legalName });
      } else {
        const employerId = getEmployerId(data);
        const res = await Employer.getById(employerId);
        employer = res?.[0]?.docs?.[0];
        const { legalFirstName, legalName } = employer?.personnalInformation?.[0];
        resolve({ legalFirstName, legalName });
      }
    } catch (e) {
      reject(e);
    }
  });

export const getEmployerId = (data) => {
  let employerId = data?.Employer?._id ? data?.Employer?._id : data?.Employer;
  if (data?.Employer?.[0] && typeof data?.Employer?.[0] !== "string") {
    employerId = data?.Employer?.[0]?._id
      ? data?.Employer?.[0]?._id
      : data?.Employer?.[0];
  } else if (data.employerId) {
    employerId = data?.employerId?._id
      ? data?.employerId?._id
      : data?.employerId;
  }
  if (Array.isArray(employerId)) return employerId[0];
  return employerId;
};
