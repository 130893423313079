import { customizeError, getResponseData, getResponseQuery } from "@helpers/_functions";
import BaseService from "./base.service";
import http from "../../src/http-common";

class EmployeeStatusService extends BaseService {
  constructor() {
    super("employer-status");
  }
  getEmployeeStatusList(userId) {
    return getResponseQuery(
      http.get(`${this.path}/list/${userId}`),
    );
  }

  getByEmployer(employeId, params = {}) {
    return http.get(`${this.path}/getByEmployer/${employeId}`, { params })
      .then(res => getResponseData(res))
      .catch((err) => Promise.reject(customizeError(err)));
  }
}

export default new EmployeeStatusService();
