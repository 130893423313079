import React, {
  Fragment,
  useState,
  useMemo,
  useCallback,
  /*useEffect*/
} from "react";

import { useTranslation } from "react-i18next";

import { Listbox, Transition } from "@headlessui/react";
import { Caret } from "@routes/IconeSvg";

import ResetFilter from "../ResetFilter";
import SearchInput from "../SearchInput"; 
import ListboxOption from "./ListboxOption";
import { flatNestedArrayOfObjects, removeGroupAncestorsWhenChildPresent, formatSelectedGroupsForFilter } from "@helpers/_functions";

function isElementInList(element, list) {
  return list.some((listitem) => listitem._id === element._id);
}

// function toggleElementFromArray(element, array) {
//   if (isElementInList(element, array)) {
//     return array.filter((arrayItem) => arrayItem._id !== element._id);
//   }

//   return array.concat(element);
// }

const className = {
  normal:
    "arh-bg-white arh-border-greyBorderInput arh-text-darkColor placeholder:arh-text-colorPlaceholderInputSimple",
  invalid:
    "arh-bg-invalidBackgroundInput arh-border-invalidColorTextInput arh-text-invalidColorTextInput placeholder:arh-text-invalidColorTextInput",
};

/**
 * @typedef Props
 * @property {string} placeholder
 * @property {string} name
 * @property {object[]} selectedValue - is now a flat array of selected group and subgroups combined
 * @property {Function} setSelectedValue
 * @property {object[]} optionList
 * @property {boolean=} invalid
 * @property {boolean=} withSearch
 * @property {boolean=} showReset
 * @property {string=} label - Label text
 * @property {string=} classLabel - La class qu'aura le tag label
 * @property {boolean=} readOnly
 * @property {boolean=} topView - Par default false
 * - Si true, le dropdown se met au dessus de l'input
 * @property {boolean=} defaultWidth - Par default false
 * - Si true, le dropdown prends la même taille que l'input
 * @property {boolean=} classParentList
 * @property {"green"|"orange"|"blue"|"gray"=} color - Couleur du checkbox
 */

/**
 *
 * @param {Props} props
 * @returns
 */
export default function ListboxGroupe(props) {
  const {
    // requiredValues
    placeholder,
    selectedValue,
    setSelectedValue,
    optionList = [],
    name,

    invalid = false,
    withSearch = false,
    showReset = false,
    topView,
    defaultWidth,
    classParentList,
    readOnly = false,
    label,
    zIndex3,
  } = props;


  const { t } = useTranslation("beginEnd.departure");

  const formatFlatGroup = ({ _id, title, ancestors = [] }) => ({ _id, title, ancestors })

  const flattedGroupList = useMemo(() => {
    const flattedGroups = flatNestedArrayOfObjects(optionList, "subgroupes")
    return flattedGroups.map((group) => formatFlatGroup(group))
  }, [optionList])

  const getGroupAncestors = (groupe) => {
    const groupOnFlat = flattedGroupList.find((fGroup) => fGroup._id === groupe._id);
    if (!groupOnFlat) return [];
    
    return flattedGroupList.filter((lGroup) => Array.isArray(groupOnFlat.ancestors) && groupOnFlat.ancestors.includes(lGroup._id));
  }

  const getGroupesWithPropertie = (selectedValue) => {
    if (!Array.isArray(selectedValue))
      return []
    if (selectedValue[0]?._id) 
      return selectedValue;
    return flattedGroupList.filter((group) => selectedValue.includes(group?._id));
  }

  const selectionsWithAncestors = useMemo(() => {
    let _selectedValue = getGroupesWithPropertie(selectedValue);
    _selectedValue = removeGroupAncestorsWhenChildPresent(_selectedValue);
    _selectedValue = _selectedValue.reduce((groupWithAncestors, sGroupe) => {
      return groupWithAncestors.concat([...(getGroupAncestors(sGroupe)), sGroupe])
    }, [])
    return _selectedValue;
  }, [selectedValue, optionList])

  const onGroupClicked = (groupe) => {
    let selectedValueWithProperties = getGroupesWithPropertie(selectedValue);
    const setDeselect = () => {
      const groupChilds = flattedGroupList.filter((lGroup) => Array.isArray(lGroup.ancestors) && lGroup.ancestors.includes(groupe._id));
      let selectedGroups = [groupe, ...groupChilds];
      const _selectedValue = selectedValueWithProperties.filter((sValue) => !selectedGroups.some((sGroup) => sGroup._id === sValue._id));
      setSelectedValue(formatSelectedGroupsForFilter(_selectedValue));
    }

    const setSelect = () => {
      // const currentGroupAncestors = getGroupAncestors(groupe);
      // const _selectedValueNoCurrentAncestors = selectedValueWithProperties.filter((sGroup) => !currentGroupAncestors.some((ancestor) => ancestor._id === sGroup._id))
      // const _selectedValue = [..._selectedValueNoCurrentAncestors, ...currentGroupAncestors, groupe];
      const _selectedValue = [...selectedValueWithProperties, groupe];
      setSelectedValue(formatSelectedGroupsForFilter(_selectedValue));
    }

    if (selectedValue.some((sValueId) => sValueId === groupe._id)) {
      setDeselect();
    } else {
      setSelect();
    }
  }

  const isGroupeSelected = useCallback(
    (groupe) => {
      return isElementInList(groupe, selectionsWithAncestors);
    },
    [selectionsWithAncestors],
  );

  const selectTextValue = useMemo(() => {

    if (!selectedValue?.length) {
      return "";
    }
    

    let text = [];
    for (let groupe of selectionsWithAncestors) {
      if (!text.includes(groupe.title))
        text.push(groupe.title);
    }

    return text.join(", ");
  }, [selectionsWithAncestors]);

  const [searchValue, setSearchValue] = useState("");
  const [onSearch, setOnSearch] = useState(false);
  const filteredOptionList = useMemo(() => {
    setOnSearch(true);
    let lists = [];
    if (!Array.isArray(optionList)) {
      console.error("optionList should be an array");
      lists = [];
    } else if (!withSearch || !searchValue) {
      lists = optionList;
    } else {
      const upperCaseSearch = searchValue.toUpperCase();
      lists = optionList.filter((groupe) => {
        if (groupe.title.toUpperCase().includes(upperCaseSearch)) {
          return true;
        }

        // return groupe.subGroups.some((sousgroupe) =>
        //   sousgroupe.title.toUpperCase().includes(upperCaseSearch),
        // );
      
        if (Array.isArray(groupe.subgroupes)) {
          const flattedSubgroupes = flatNestedArrayOfObjects(groupe.subgroupes, "subgroupes");
          return flattedSubgroupes.some((subgroupe) => subgroupe.title?.toUpperCase().includes(upperCaseSearch));
        }
      
        return false;
      });
    }
    setTimeout(() => {
      setOnSearch(false);
    });
    return lists;
  }, [searchValue, withSearch, optionList]);

  function resetValue(e) {
    e.stopPropagation();
    setSelectedValue([]);
  }

  const isShowResetFilter = showReset && selectedValue?.length > 0;

  return (
    <>
      {label && (
        <label
          className={`
            arh-mb-1 arh-w-full arh-text-left arh-font-poppins arh-text-xs arh-font-medium 
            ${
              invalid
                ? " arh-text-invalidColorTextInput "
                : readOnly
                  ? "arh-text-greyDisabledText "
                  : "arh-text-black "
            } ${props?.classLabel}
          `}
        >
          {label}
        </label>
      )}
      <Listbox name={name} disabled={readOnly} multiple>
        {({ open }) => (
          <div className={classParentList + " arh-relative arh-w-full"}>
            <Listbox.Button
              className={`arh-relative arh-w-full arh-cursor-pointer arh-rounded arh-border arh-border-solid arh-px-6 arh-py-3 arh-pr-8 arh-text-left arh-font-poppins arh-text-xs arh-font-normal focus:arh-outline-none focus-visible:arh-border-greyBorderInput focus-visible:arh-ring-2 focus-visible:arh-ring-greyBorderInput focus-visible:arh-ring-opacity-75 focus-visible:arh-ring-offset-2 focus-visible:arh-ring-offset-greyColor disabled:arh-pointer-events-none disabled:arh-cursor-none disabled:arh-border-greyDisabledBorder disabled:arh-bg-greyDisabled disabled:arh-text-greyDisabledText ${
                invalid ? className["invalid"] : className["normal"]
              }`}
            >
              {isShowResetFilter && <ResetFilter onClick={resetValue} />}
              <input
                className={`arh-pointer-events-none arh-block arh-h-full arh-w-full arh-max-w-full arh-truncate arh-text-ellipsis arh-border-0 arh-bg-transparent arh-text-darkColor placeholder:arh-text-xs placeholder:arh-text-colorPlaceholderInputSimple disabled:arh-text-greyDisabledText ${
                  isShowResetFilter ? "arh-ml-2.5 " : ""
                } ${invalid ? className["invalid"] : ""}`}
                type="text"
                placeholder={placeholder}
                value={selectTextValue}
                onChange={() => {}}
                disabled={readOnly}
              />
              <span className="arh-pointer-events-none arh-absolute arh-inset-y-0 arh-right-0 arh-flex arh-items-center arh-pr-18">
                <Caret
                  className={`${open ? "arh-rotate-90" : "arh-rotate-0"}`}
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="arh-transition arh-ease-in arh-duration-100"
              leaveFrom="arh-opacity-100"
              leaveTo="arh-opacity-0"
            >
              <Listbox.Options
                className={`
            ${topView && "arh-bottom-full arh-mb-1"}
            arh-absolute arh-mt-1 arh-max-h-60 arh-w-full arh-overflow-y-auto arh-overflow-x-hidden arh-rounded arh-bg-white arh-px-4 arh-py-[0.938rem] arh-text-xs arh-shadow-xs
            ${zIndex3 ? "arh-z-[3]" : "arh-z-20"} ${
              !defaultWidth && "3xl:arh-w-[120%] desktop-l:arh-w-full"
            }`}
              >
                <div className="arh-relative arh-w-full ">
                  {withSearch && (
                    <div className="arh-sticky -arh-top-[1rem] arh-z-[9] arh-w-full arh-bg-white arh-py-[1rem] ">
                      <SearchInput
                        placeholder={t("Rechercher")}
                        value={searchValue}
                        onChange={setSearchValue}
                        onSearch={onSearch}
                      />
                    </div>
                  )}

                  {filteredOptionList.map((groupe) => (
                    <ListboxOption
                      key={groupe._id}
                      groupe={groupe}
                      // onGroupeClicked={onGroupeClicked}
                      // onSubgroupClicked={onSubgroupClicked}
                      // isGroupeSelected={isGroupeSelected}
                      // isSousGroupeSelected={isSousGroupeSelected}
                      onClick={onGroupClicked}
                      // isSelected={isGroupeSelected}
                      isSelected={isGroupeSelected}
                      color={props.color}
                    />
                  ))}
                </div>
              </Listbox.Options>
            </Transition>
          </div>
        )}
      </Listbox>
    </>
  );
}
