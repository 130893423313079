import BaseOnboardingService from "./baseOnboarding.service";
import {
  customizeError,
  checkEnterpriseId,
  getResponseData
} from "@helpers/_functions";
import http from "../../http-common";

class InsurancePlanNumberService extends BaseOnboardingService {
  constructor() {
    super("insurance-planNumber");
  }
  getOptionsByEnterprise() {
    return http
      .get(`${this.path}/options/${checkEnterpriseId()}`)
      .then((response) => getResponseData(response))
      .catch((err) => Promise.reject(customizeError(err)));
  }
}

export default new InsurancePlanNumberService();
