import React, { Fragment, useRef, useState } from "react";

import { useTranslation } from "react-i18next";

import { Menu, Transition } from "@headlessui/react";
import { SvgDotsMenu } from "@routes/IconeSvg";

/**
 *
 * @param {object} props
 * @param {Array} props.lists
 * @param {boolean=} props.withIcon
 * @param {function=} props.onOpenCloseMenu
 * @param {String=} props.parentClass
 * @param {boolean=} props.locked
 * @param {boolean=} props.bottomView
 * @param {String=} props.itemClass
 * @returns
 */
const Dropdown = ({
  lists,
  withIcon = false,
  onOpenCloseMenu,
  parentClass = "",
  locked = false,
  bottomView = false,
  itemClass = ""
}) => {
  const { t } = useTranslation("components.menu");

  const [isTopView, setIsTopView] = useState(false);
  const wrapperRef = useRef(null);

  const handleClick = (_open) => {
    if (!bottomView && wrapperRef.current) {
      const rect = wrapperRef.current.getBoundingClientRect();
      // @ts-ignore
      const viewportHeight = window.innerHeight;
      const dropdownHeight = 150;

      const spaceAbove = rect.top;
      const spaceBelow = viewportHeight - rect.bottom;

      if (spaceBelow < dropdownHeight && spaceAbove > dropdownHeight) {
        setIsTopView(true);
      } else {
        setIsTopView(false);
      }
    }
    if (onOpenCloseMenu) onOpenCloseMenu(!_open);
  };

  return (
    <div className={parentClass}>
      <Menu
        as="div"
        className="arh-relative arh-inline-block"
      >
        {({ open }) => {
          return (
            <>
              <Menu.Button
                ref={wrapperRef}
                onClick={() => handleClick(open)}
                disabled={locked}
                className={`${locked && "arh-cursor-default"}
                arh-flex arh-h-8 arh-w-8 arh-items-center arh-justify-center arh-rounded-full arh-border-0 arh-bg-transparent hover:arh-bg-gray-200 active:arh-bg-greyActive
              `}
              >
                <SvgDotsMenu />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="arh-transition ease-out arh-duration-100"
                enterFrom="arh-transform opacity-0 arh-scale-95"
                enterTo="arh-transform arh-opacity-100 arh-scale-100"
                leave="arh-transition arh-ease-in arh-duration-75"
                leaveFrom="arh-transform arh-opacity-100 arh-scale-100"
                leaveTo="arh-transform arh-opacity-0 arh-scale-95"
              >
                <Menu.Items
                  className={`${isTopView ? "arh-bottom-full arh-mb-1" : "arh-mt-1"} arh-focus:outline-none arh-absolute arh-right-0 arh-z-10 arh-w-fit arh-min-w-[228px] arh-rounded-md arh-bg-white arh-p-4 arh-shadow-xl ${itemClass}`}
                >
                  {lists.map((_list) => {
                    return (
                      <Menu.Item
                        as="div"
                        onClick={_list.click}
                        // toggle={false}
                        key={_list.title}
                        disabled={_list?.disabled || false}
                        id={_list.id || "id"}
                        ref={_list.ref || null}
                      >
                        <div
                          className={`arh-flex arh-cursor-pointer arh-items-center arh-justify-start arh-space-x-2 arh-rounded arh-p-2 hover:arh-bg-gray-200 active:arh-bg-greyActive`}
                        >
                          {withIcon && <span> {_list.icon} </span>}

                          <span className="arh-font-poppins arh-text-xs arh-font-medium arh-text-black">
                            {t(_list?.title)}
                          </span>
                        </div>
                      </Menu.Item>
                    );
                  })}
                </Menu.Items>
              </Transition>
            </>
          );
        }}
      </Menu>
    </div>
  );
};
export default React.memo(Dropdown);
